import React from 'react'

export const Testcomponent = () => {
  return (
        <div>
            <div>
                <h1>제목</h1>
                <button>버튼</button>
            </div>
                
        </div>
  )
}

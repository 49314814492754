import React from 'react'
import { useNavigate } from 'react-router-dom'
import s from './GroupManagement.module.css';

export const GroupManagement = () => {
    const nav = useNavigate();
  return (
    <div className={s.contianer}>
      
    </div>
    // <div style={{minHeight:'100vh',minWidth:'80vw',overflow:'auto',display:'flex',justifyContent:'center',adivgnItems:'center'}}>
    //     <div>
    //         <h1 style={{textAlign:'center'}}>🚨</h1>
    //         <h1>🚧 공사 중.. 🚧</h1>
    //         <div style={{textAlign:'center'}}>
    //             <h3>추가 예정 기능</h3>
    //             <div>그룹 인원 추가</div>
    //             <div>그룹 삭제</div>
    //             <div>그룹 이름 변경</div>
    //             <div>그룹 설명 변경</div>
    //             <div>그룹 파일 관리</div>
    //             <br/>
    //         </div>
    //     <button onClick={()=>nav(-1)}>뒤로가기</button>
    //     </div>
    // </div>
  )
}
